import React from 'react'
import about from "../assets/images/about-logo.jpg";
import { FaPhone } from 'react-icons/fa6';
import about_bg from "../assets/about_bg.jpg";
const About = () => {

    return (
        <div id='about' className='bg-black '>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-5 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2 '>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[500px] ' />
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color '>About Us</h4>
                        <div className='text-3xl md:text-4xl font-bold mt-4 text-white'>Welcome to One Way Drop Taxis</div>
                        <p className='text-black opacity-60 md:text-lg mt-5 text-white'>Discover the convenience and affordability of One Way Drop Taxis, your premier drop taxi service across Tamil Nadu, Pondicherry, and Bangalore. Say goodbye to costly return fares and hello to a seamless travel experience.</p>
                        <div className=' mt-5'>
                            <a
                                href="tel:+919092333448"
                                className=" rounded-md th-btn hover:bg-black transition-all ease-linear px-3 py-2"  >
                                    
                                <span className=" flex gap-x-2 items-center justify-center">
                                    <FaPhone size={17} /> +91 9092333448
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About