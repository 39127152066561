import React from 'react'
import bgslider from '../../assets/images/bg_slide.jpg'
const PricePlanComponents = ({place , image , oneway ,roundtrip}) => {
  const divStyle = {
    backgroundImage: `url(${bgslider})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

  };
  return (
    <div className=" py-6 px-4 rounded-lg shadow-lg border  bg-white h-full">
      <div className=' flex flex-col   justify-between flex-1 h-full gap-3' >
    <div className=' flex flex-col justify-center items-center '>
      <p className=" font-bold text-center text-2xl">{place}</p>
      <div className='h-44 w-44 rounded-full shadow-2xl bg-[#fac12f] flex justify-center items-center mt-5 outline outline-3 outline-white' style={divStyle}><img src={image} className=" w-[145px] mx-auto" /> </div> 
      </div>
      <div className=" flex gap-x-2 justify-between mt-3 pb-3  flex-1">
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-bold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl ">{oneway}</span>
              </span>
              <span className=" opacity-50 font-semibold">One Way</span>
          </div>
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-semibold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl">{roundtrip}</span>
              </span>
              <span className=" opacity-50 font-semibold">Round Trip</span>
          </div>
      </div>
      <div className=" mt-3">
          <a  href="tel:+919092333448"  className="th-btn-reverse w-full">CHOOSE CAB</a>
      </div>
    </div>
  </div>
  )
}

export default PricePlanComponents
