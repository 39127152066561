import React from "react";
import img1 from "../../assets/images/corporate_ser.jpg";
import img2 from "../../assets/images/city_rides.jpg";
import img3 from "../../assets/images/airport-rides.jpg";
import img4 from "../../assets/images/intercity-travel.jpg";
const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-3 mt-6'>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img3} className="rounded-lg"/>
            </div>
            <h4 className="font-bold text-center text-3xl">Airport Transfers</h4>
            <p className="text-center text-lg">Hassle-free pickups and drop-offs to and from major airports in the region</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img2} className="rounded-lg h-[210px]"/>
            </div>
            <h4 className="font-bold text-center text-3xl">City Rides</h4>
            <p className="text-center text-lg">Navigate through busy city streets with ease and efficiency.</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img4} className="rounded-lg h-[210px]"/>
            </div>
            <h4 className="font-bold text-center text-3xl">Intercity Travel</h4>
            <p className="text-center text-lg">Comfortable and reliable transport for long-distance travel between cities.</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img1} className="rounded-lg h-[210px]"/>
            </div>
            <h4 className="font-bold text-center text-3xl">Corporate Services</h4>
            <p className="text-center text-lg">Tailored solutions for business travel needs, ensuring punctuality and professionalism.</p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default OurServices;
